import * as React from "react";

const Globe = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17.605}
    height={17.594}
    {...props}
  >
    <g fill="none" data-name="Group 17182">
      <g stroke="#fff" data-name="Ellipse 422" transform="translate(.011)">
        <circle cx={8.797} cy={8.797} r={8.797} stroke="none" />
        <circle cx={8.797} cy={8.797} r={8.297} />
      </g>
      <g stroke="#fff" data-name="Ellipse 423" transform="translate(5.567)">
        <ellipse cx={3.241} cy={8.797} stroke="none" rx={3.241} ry={8.797} />
        <ellipse cx={3.241} cy={8.797} rx={2.741} ry={8.297} />
      </g>
      <g data-name="Path 11917">
        <path d="M17.605 8.797c-.041 3.647-3.939 3.241-8.8 3.241s-9.051.454-8.8-3.241 3.945-3.241 8.8-3.241 8.84-.407 8.8 3.241Z" />
        <path
          fill="#fff"
          d="M16.605 8.742c0-.813-.252-1.157-.441-1.345-.897-.889-3.383-.87-6.016-.848-.435.003-.884.007-1.34.007-.498 0-.983-.005-1.452-.01-1.98-.019-3.689-.036-4.833.32-.844.263-1.425.704-1.514 2A3.537 3.537 0 0 0 1 9.106c0 .675.216.967.379 1.128.869.86 3.35.837 5.975.812.472-.005.96-.01 1.454-.01.457 0 .906.004 1.34.008 2.048.016 3.816.03 5.004-.33 1.047-.315 1.44-.838 1.453-1.93v-.043m1 0v.055c-.04 3.647-3.939 3.24-8.797 3.24-4.858 0-9.051.454-8.797-3.24.255-3.695 3.939-3.241 8.797-3.241 4.834 0 8.797-.402 8.797 3.186Z"
        />
      </g>
    </g>
  </svg>
);
export default Globe;

import { AppBar, Box, Button } from "@mui/material";
import classNames from "classnames";
import { useMemo, useState } from "react";
import { useIsTablet } from "../../../hooks/common.hooks";
import AnimateMenuIcon from "./animate-menu-icon";
import { useCompanyInfo } from "../../../hooks/company.hooks";

const NavigationBar = ({ signupUrl = "" }) => {
  const isTablet = useIsTablet();
  const { lightLogo } = useCompanyInfo();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const handleClickMenu = () => {
    setIsMenuOpen((prevState) => !prevState);
  };

  const navLinks = useMemo(() => {
    if (isTablet) {
      return [
        {
          title: "Login",
          url: `/login`,
        },
      ];
    }
    return [
      {
        title: "Login",
        url: `/login`,
      },
    ];
  }, [isTablet]);

  return (
    <>
      <AppBar position="sticky">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          className="sharing-navigation__container"
        >
          <Box
            display="flex"
            className="sharing-navigation__home"
            alignItems="center"
          >
            <div
              onClick={handleClickMenu}
              className="sharing-navigation-icon__container hamburger"
            >
              <AnimateMenuIcon className={isMenuOpen ? "open" : ""} />
            </div>
            {lightLogo && (
              <div className="sharing-navigation-icon__container">
                <img src={lightLogo} alt="experity-icon" />
              </div>
            )}
          </Box>
          <Box
            display="flex"
            alignItems="center"
            className={classNames("sharing-navigation-button__container", {
              open: isMenuOpen,
            })}
            justifyContent="space-between"
          >
            {!isTablet && <a href="tel:0289732732">(02) 89 732 732</a>}

            {navLinks.map(({ title, url }) => (
              <a target="_blank" href={url} key={title} rel="noreferrer">
                {title}
              </a>
            ))}
            <Button
              button
              className="sharing-navigation__button"
              target="_blank"
              href={signupUrl}
            >
              Get Started
            </Button>
          </Box>
        </Box>
      </AppBar>
      <div
        className={classNames("sharing-mobile-menu", {
          open: isMenuOpen,
        })}
      >
        <div className="sharing-mobile-menu-login__container">
          <Button className="sharing-button-light" href="/login">
            Login
          </Button>
          <Button
            target="_blank"
            className="sharing-button-light"
            href={signupUrl}
          >
            Get Started
          </Button>
        </div>
      </div>
    </>
  );
};

export default NavigationBar;

import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  createCompanyAdmin,
  createStaff,
  deleteStaff,
  getStaffInfo,
  getStaffList,
  updateStaffDetailsById,
  uploadStaffImage,
} from "../api-services/brokers";
import { getStaffListByCompanyId } from "../api-services/company";
import { USER_ROLE } from "../commons/enum";
import { useIsLoggedIn, useMyInfo } from "./user.hooks";

export const useStaffInfo = () => {
  const { data: userInfo } = useMyInfo();
  const isLoggedIn = useIsLoggedIn();
  return useQuery("staffInfo", () => getStaffInfo(), {
    enabled: isLoggedIn && userInfo?.userRole !== USER_ROLE.ADMIN,
    retry: false,
  });
};

export const useDeleteStaff = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteStaff, {
    onSuccess: () => {
      return queryClient.invalidateQueries("staffList");
    },
  });
};

export const useStaffList = () => {
  const isLoggedIn = useIsLoggedIn();
  return useQuery("staffList", getStaffList, {
    enabled: isLoggedIn,
  });
};

export const useStaffListByCompanyId = (companyId) => {
  const isLoggedIn = useIsLoggedIn();
  return useQuery(
    ["staffList", companyId],
    () => getStaffListByCompanyId(companyId),
    {
      enabled: isLoggedIn && !!companyId,
    }
  );
};

export const useStaffListByUserRole = (userRole) => {
  const { data: staffList } = useStaffList();
  const { data: staffInfo } = useStaffInfo();
  const { data: staffListByCompany } = useStaffListByCompanyId(
    staffInfo?.companyId
  );
  if (userRole === USER_ROLE.ADMIN) {
    return staffList;
  }
  if (userRole === USER_ROLE.STAFF) {
    return staffListByCompany;
  }
  return [];
};

export const useUpdateStaffImage = () => {
  return useMutation(uploadStaffImage);
};

export const useUpdateStaffById = () => {
  return useMutation((data) => {
    return updateStaffDetailsById(data.id, data.params);
  });
};

export const useCreateCompanyAdmin = () => {
  return useMutation(createCompanyAdmin);
};

export const useCreateStaff = () => {
  const queryClient = useQueryClient();
  return useMutation(createStaff, {
    onSuccess: () => {
      return queryClient.invalidateQueries("staffList");
    },
  });
};

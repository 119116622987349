import "../../scss/sharing-referral.scss";
import NavigationBar from "./components/navigation-bar";
import SharingFooter from "./components/sharing-footer";

const SharingLayout = ({ children }) => {
  return (
    <>
      <NavigationBar signupUrl="signup" />
      {children}
      <SharingFooter />
    </>
  );
};

SharingLayout.propTypes = {};

export default SharingLayout;

/* eslint-disable react/display-name */

const ReactQuery = require("react-query");
const { ThemeProvider } = require("@mui/material/styles");
const Layout = require("./src/components/Layout").default;
const { theme } = require("./src/utils/theme");

const { QueryClient, QueryClientProvider } = ReactQuery;

// Wraps every page in a component
exports.wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};

exports.onRouteUpdate = ({ location, prevLocation }) => {
  window.analytics &&
    window.analytics.page(location.href, {
      path: location.pathname,
      url: location.href,
      referrer: prevLocation ? prevLocation.href : null,
    });
};

exports.wrapRootElement = ({ element }) => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: Infinity,
      },
    },
  });
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>{element}</ThemeProvider>
    </QueryClientProvider>
  );
};

import { navigate } from "gatsby";
import { useSnackbar } from "notistack";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  companyApproval,
  createHomePageDetails,
  getCompanyConnectiveDetails,
  getCompanyInfo,
  getCompanyList,
  updateCompany,
  updateHomePageDetails,
} from "../api-services/company";
import { USER_ROLE } from "../commons/enum";
import { getcompanyDomain } from "../utils/get-params-value";
import { useMyInfo } from "./user.hooks";

export const useCompanyList = () => {
  const { data: userInfo } = useMyInfo();
  return useQuery("companyList", getCompanyList, {
    enabled: userInfo?.userRole !== USER_ROLE.USER,
  });
};

export const useCreateHomePageDetails = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(createHomePageDetails, {
    onSuccess: () => {
      enqueueSnackbar("Your company details has been created", {
        variant: "success",
      });
      queryClient.invalidateQueries("companyInfo");
    },
  });
};

export const useUpdateHomePageDetails = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(updateHomePageDetails, {
    onSuccess: () => {
      enqueueSnackbar("Your company details has been updated", {
        variant: "success",
      });
      queryClient.invalidateQueries("companyInfo");
    },
  });
};

export const useCompanyInfo = () => {
  const companyDomain = getcompanyDomain();
  return useQuery(
    ["companyInfo", companyDomain],
    () =>
      getCompanyInfo({
        params: { domain: companyDomain },
      }),
    {
      onSuccess: (data) => {
        const pageLayout = document.documentElement;
        pageLayout.style.setProperty(
          "--secondaryColor",
          `${data?.theme?.secondaryColor || "#162029"}`
        );
        pageLayout.style.setProperty(
          "--primaryColor",
          `${data?.theme?.primaryColor || "#04f73d"}`
        );
      },
      onError: (error) => {
        if (error.status === 404) {
          navigate("/page-error");
        }
      },
      retry: 1,
    }
  );
};
export const useCompanyInfoById = (companyId) => {
  return useQuery(
    ["companyInfo", companyId],
    () =>
      getCompanyInfo({
        params: { companyId },
      }),
    {
      enabled: !!companyId,
      onError: (error) => {
        if (error.status === 404) {
          navigate("/page-error");
        }
      },
      retry: 1,
    }
  );
};
export const useCompanyConnectiveDetails = () => {
  const companyDomain = getcompanyDomain();
  const { data: companyConnectiveDetails } = useQuery(
    ["CompanyConnectiveDetails", companyDomain],
    () =>
      getCompanyConnectiveDetails({
        params: { domain: companyDomain },
      })
  );
  return companyConnectiveDetails;
};

export const useCompanyTheme = () => {
  const { data: companyInfo } = useCompanyInfo();
  return companyInfo?.theme;
};
export const useCompanyHomePageDetails = () => {
  const { data: companyInfo } = useCompanyInfo();
  return companyInfo?.homepageDetails;
};

export const useCompanyLogo = () => {
  const companyTheme = useCompanyTheme();
  const companyLightLogo = companyTheme?.iconURL || companyTheme?.logoUrl;
  const companyDarkLogo =
    companyTheme?.iconDarkUrl || companyTheme?.logoDarkUrl;
  return {
    lightLogo: companyLightLogo,
    darkLogo: companyDarkLogo,
  };
};

export const useUpdateCompany = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(updateCompany, {
    onSuccess: () => {
      queryClient.invalidateQueries("companyTheme");
      queryClient.invalidateQueries("companyInfo");
      queryClient.invalidateQueries("companyConnectiveDetails");
      enqueueSnackbar("Your company details has been updated", {
        variant: "success",
      });
    },
  });
};

export const useCompanyApproval = () => {
  const queryClient = useQueryClient();
  return useMutation(companyApproval, {
    onSuccess: () => {
      queryClient.invalidateQueries("companyList");
    },
  });
};

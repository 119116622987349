import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      id="prefix__Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 23.9 23.9"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {
          ".prefix__st0{stroke:#fff;stroke-width:1.5}.prefix__st0,.prefix__st1{fill:none}"
        }
      </style>
      <g id="prefix__Group_6745" transform="translate(-17.871 -474.868)">
        <g id="prefix__Group_5357" transform="translate(-8274.389 -15.132)">
          <circle
            id="prefix__Ellipse_234"
            cx={8300.5}
            cy={498}
            r={3.5}
            className="icon-fill"
          />
          <path
            id="prefix__Path_2124"
            className="prefix__st0"
            d="M8293.5 508.7h11.7c.6 0 .1-1.6-1.3-3.2-.9-1-2.5-2.1-3-1.8-1.1.7-2.5.7-3.6 0-.5-.4-1.8.3-3 1.8-1 1.2-1.8 3.2-.8 3.2z"
          />
          <g id="prefix__Ellipse_235" transform="translate(8295 491)">
            <circle className="prefix__st1" cx={4} cy={8.5} r={4} />
            <circle className="prefix__st0" cx={4} cy={8.5} r={3.2} />
          </g>
        </g>
        <g id="prefix__Group_5360" transform="translate(-8264.389 -15.132)">
          <circle
            id="prefix__Ellipse_234-2"
            cx={8300.5}
            cy={498}
            r={3.5}
            className="icon-fill"
          />
          <path
            id="prefix__Path_2124-2"
            className="prefix__st0"
            d="M8293.5 508.7h11.7c.6 0 .1-1.6-1.3-3.2-.9-1-2.5-2.1-3-1.8-1.1.7-2.5.7-3.6 0-.5-.4-1.8.3-3 1.8-1 1.2-1.8 3.2-.8 3.2z"
          />
          <g id="prefix__Ellipse_235-2" transform="translate(8295 491)">
            <circle className="prefix__st1" cx={4} cy={8.5} r={4} />
            <circle className="prefix__st0" cx={4} cy={8.5} r={3.2} />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;

import React from "react";

const AnimateMenuIcon = ({ className = "" }) => {
  return (
    <div className={`hamburger-icon ${className}`}>
      <span className="hamburger-top" />
      <span className="hamburger-mid" />
      <span className="hamburger-bot" />
    </div>
  );
};

export default AnimateMenuIcon;

import { navigate } from "gatsby";
import { useEffect } from "react";
import { useIsInDashboardRoutes } from "../../hooks/common.hooks";
import { useIsLoggedIn, useMyInfo } from "../../hooks/user.hooks";

const UserAuthenticationNavigate = ({ children, location }) => {
  const { isError } = useMyInfo();
  const pathname = location.pathname.replace(/\//g, "");
  const isInDashboardRoutes = useIsInDashboardRoutes(pathname);
  const isLoggedIn = useIsLoggedIn();
  useEffect(() => {
    if (isError && !isLoggedIn && isInDashboardRoutes) {
      navigate("/login");
    }
  }, [isError, isInDashboardRoutes, isLoggedIn]);
  return children;
};

export default UserAuthenticationNavigate;

import axiosClient from "./axiosClient";

export const getStaffInfo = () => {
  const url = `/brokers`;
  return axiosClient.get(url);
};

export const getStaffInfoByReferralCode = (referralCode) => {
  const url = `/brokers/referral?code=${referralCode}`;
  return axiosClient.get(url);
};

export const getStaffList = () => {
  const url = `/brokers/staffList`;
  return axiosClient.get(url);
};
export const uploadStaffImage = (params) => {
  const url = `/brokers/uploadImage`;
  return axiosClient.post(url, params);
};

export const updateStaffDetailsById = (id, params) => {
  const url = `/brokers/${id}`;
  return axiosClient.put(url, params);
};

export const createCompanyAdmin = (createBrokerParams) => {
  const url = `/brokers/createCompanyAdmin`;
  return axiosClient.post(url, createBrokerParams);
};

export const createStaff = (createBrokerParams) => {
  const url = `/brokers/create`;
  return axiosClient.post(url, createBrokerParams);
};

export const deleteStaff = (brokerId) => {
  const url = `/brokers/${brokerId}`;
  return axiosClient.delete(url);
};

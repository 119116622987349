import { graphql, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import { Helmet } from "react-helmet";
import Facebook from "./Facebook";
import Twitter from "./Twitter";

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        siteUrl
        defaultTitle: title
        defaultDescription: description
        defaultBanner: banner
        siteLanguage
        ogLanguage
        twitter
        facebook
      }
    }
  }
`;

const SEO = ({ title, desc, banner, pathname }) => {
  const { site } = useStaticQuery(query);

  const {
    siteMetadata: {
      siteUrl,
      defaultTitle,
      defaultDescription,
      defaultBanner,
      siteLanguage,
      ogLanguage,
      twitter,
      facebook,
    },
  } = site;
  const seo = {
    title: title || defaultTitle,
    description: desc || defaultDescription,
    image: `${siteUrl}${banner || defaultBanner}`,
    url: `${siteUrl}${pathname || ""}`,
  };
  const schemaOrgWebPage = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    "@id": "#website",
    url: siteUrl,
    name: defaultTitle,
    potentialAction: {
      "@type": "SearchAction",
      target: "https://www.herobroker.org/?s={search_term_string}",
      "query-input": "required name=search_term_string",
    },
  };
  const schemaOrg = {
    "@context": "https://schema.org",
    "@type": "Organization",
    url: "https://www.herobroker.org/",
    sameAs: [
      "https://www.facebook.com/herobroker/",
      "https://twitter.com/herobroker",
    ],
    "@id": "https://www.herobroker.org/#organization",
    name: "Hero Broker",
    logo: "https://www.herobroker.org/wp-content/uploads/2017/02/App-icon.jpg",
  };
  return (
    <>
      <Helmet title={seo.title}>
        <html lang={siteLanguage} />
        <meta name="description" content={seo.description} />
        <meta name="image" content={seo.image} />
        <link rel="canonical" href={seo.url} />
        <script type="application/ld+json">
          {JSON.stringify(schemaOrgWebPage)}
        </script>
        <script type="application/ld+json">{JSON.stringify(schemaOrg)}</script>
      </Helmet>
      <Facebook
        desc={seo.description}
        image={seo.image}
        title={seo.title}
        type="website"
        url={seo.url}
        locale={ogLanguage}
        name={facebook}
      />
      <Twitter
        title={seo.title}
        image={seo.image}
        desc={seo.description}
        username={twitter}
      />
    </>
  );
};

export default SEO;

SEO.propTypes = {
  title: PropTypes.string,
  pathname: PropTypes.string,
};
SEO.defaultProps = {
  title: null,
  pathname: null,
};

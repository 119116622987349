import React from "react";
import PropTypes from "prop-types";
import { Backdrop, CircularProgress } from "@mui/material";

const LoadingBackdrop = ({ isOpen = true, handleClose = () => {} }) => {
  return (
    <Backdrop className="loading-modal" open={isOpen} onClick={handleClose}>
      <div style={{ textAlign: "center" }}>
        <CircularProgress color="inherit" />
        <div>Loading Data...</div>
      </div>
    </Backdrop>
  );
};

LoadingBackdrop.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default LoadingBackdrop;

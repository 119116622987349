import { useSnackbar } from "notistack";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  getUserInfo,
  updateUserInfo,
  updateUserInfoById,
} from "../api-services/users";
import {
  cognitoChangePassword,
  isAuthenticatedUser,
} from "../utils/user-auth-provider";

export const useIsLoggedIn = () => {
  const { data: isLoggedIn } = useQuery("isLoggedIn", isAuthenticatedUser, {
    retry: false,
  });
  return isLoggedIn;
};

export const useMyInfo = () => {
  const isLoggedIn = useIsLoggedIn();
  const queryClient = useQueryClient();
  return useQuery("myInfo", getUserInfo, {
    enabled: isLoggedIn,
    retry: false,
    onSettled: () => {
      queryClient.invalidateQueries("myStaffRoles");
    },
  });
};

export const useMyStaffRoles = () => {
  const { data: myInfo, isError, isLoading } = useMyInfo();
  const queryClient = useQueryClient();
  return useQuery("myStaffRoles", () => myInfo?.staffRoles || [], {
    onSettled: () => {
      queryClient.invalidateQueries("isCompanyAdmin");
    },
    enabled: !isLoading && !isError,
  });
};

export const useIsCompanyAdmin = () => {
  const { data: staffRoles, isLoading, isError } = useMyStaffRoles();
  return useQuery(
    "isCompanyAdmin",
    () => {
      return staffRoles?.includes("COMPANY_ADMIN");
    },
    {
      enabled: !isLoading && !isError, // Only run this query if staffRoles are loaded and there's no error
    }
  );
};

export const useUpdateUser = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    (updateUserInfoParams) => updateUserInfo(updateUserInfoParams),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("myInfo");
        enqueueSnackbar("Your information has been updated", {
          variant: "success",
        });
      },
    }
  );
};

export const useClearQueryCache = () => {
  const queryClient = useQueryClient();
  return () => {
    queryClient.clear();
  };
};

export const useUpdateUserDetail = () => {
  return useMutation((data) => updateUserInfoById(data.id, data.params));
};

export const useChangePassword = ({ onSuccess, onError }) => {
  return useMutation(
    ({ currentPassword, newPassword }) =>
      cognitoChangePassword(currentPassword, newPassword),
    {
      onSuccess,
      onError,
    }
  );
};

const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const getMonth = (date) => {
  const lastMonths = [];
  for (let i = 0; i < 12; i += 1) {
    lastMonths.push(monthNames[date.getMonth()]);
    date.setMonth(date.getMonth() + 1);
    if (i === 11) lastMonths.push(monthNames[date.getMonth()]);
  }
  return lastMonths;
};

export const getCurrentMonth = (backYear = 0, backMonth = 0) => {
  const date = new Date();
  return `${date.getFullYear() - backYear}-${`0${
    date.getMonth() + 1 - backMonth
  }`.slice(-2)}`;
};

export const getCurrentMonthWithDay = (backYear = 0) => {
  const date = new Date();
  return `${date.getFullYear() - backYear}-${`0${date.getMonth() + 1}`.slice(
    -2
  )}-${`0${date.getDate()}`.slice(-2)}`;
};

export const monthFormater = (date, symbol = "-") => {
  return `${date.getFullYear()}${symbol}${`0${date.getMonth() + 1}`.slice(-2)}`;
};

export const monthDayFormater = (date) => {
  return `${date.getFullYear()}-${`0${date.getMonth() + 1}`.slice(
    -2
  )}-${`0${date.getDate()}`.slice(-2)}`;
};
export const monthDayNameFormater = (date) => {
  return `${`0${date.getDate()}`.slice(-2)} ${`${
    monthNames[date.getMonth()]
  }`} ${date.getFullYear()}`;
};

export const compareTwoMonth = (date) => {
  const currentDate = new Date();
  return currentDate.getMonth() - date.getMonth();
};

export const getLastMonths = (date, monthLength) => {
  const lastMonths = [];
  for (let i = 0; i < monthLength; i += 1) {
    lastMonths[i] = `${date.getFullYear().toString()}-${`0${
      date.getMonth() + 1
    }`.slice(-2)}`;
    date.setMonth(date.getMonth() - 1);
  }

  return lastMonths;
};

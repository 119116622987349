import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import classNames from "classnames";
import { Logout } from "../../../svgs";
import { monthDayFormater } from "../../../utils/calendar-util";
import SideBarItem from "./side-bar-item";
import { useCompanyLogo } from "../../../hooks/company.hooks";

const MobileSideBar = ({
  sideBarMenuItem,
  name,
  isExpanded = false,
  handleIsExpanded = () => {},
  handleLogout,
  gitVersion = {},
}) => {
  const { darkLogo } = useCompanyLogo();
  const { gitTagName = "", gitDate = 0 } = gitVersion;
  return (
    <div className="mobile-menu">
      <div className="side-bar-item-group top">
        <div className="header-container">
          {darkLogo && (
            <div className="header-logo">
              <img src={darkLogo} alt="Experity Icon" />
            </div>
          )}
          <div className="d-flex align-items-center">
            <div className="user-name-header">{name}</div>

            <div
              className={classNames("arrow-down", {
                expanded: isExpanded,
              })}
              onClick={handleIsExpanded}
            >
              <ExpandMoreIcon />
            </div>
          </div>
        </div>
      </div>
      <div
        className={classNames("expanded-logout-mobile", {
          "d-none": !isExpanded,
        })}
      >
        <div
          className="expanded-logout-feature-groups logout-button-group"
          onClick={handleLogout}
        >
          <SideBarItem
            icon={<Logout />}
            title="Logout"
            checked={false}
            href=""
          />
        </div>
        <div className="code-version text-right">
          <p className="m-0">Version: {gitTagName}</p>
          <p className="m-0">Updated: {monthDayFormater(new Date(gitDate))}</p>
        </div>
      </div>
      <div className="side-bar-item-group bottom">
        <div className="side-bar-item-group__container">
          {sideBarMenuItem.map((sideBarItem) => (
            <SideBarItem
              key={sideBarItem.title}
              icon={sideBarItem.icon}
              title={sideBarItem.title}
              href={sideBarItem.href}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

MobileSideBar.propTypes = {};

export default MobileSideBar;

import axiosClient from "./axiosClient";

export const getCompanyList = () => {
  const url = `/company`;
  return axiosClient.get(url);
};

export const companyApproval = ({ id, params }) => {
  const url = `/company/${id}/companyApproval`;
  return axiosClient.post(url, params);
};

export const createHomePageDetails = ({ id, params }) => {
  const url = `/company/${id}/uploadHomePageDetails`;
  return axiosClient.post(url, params);
};

export const updateHomePageDetails = ({ id, params }) => {
  const url = `/company/${id}/uploadUpdatedHomePageDetails`;
  return axiosClient.post(url, params);
};

export const getStaffListByCompanyId = (id) => {
  const url = `/company/${id}/staffList`;
  return axiosClient.get(url);
};

export const updateCompany = ({ id, params }) => {
  const url = `/company/${id}`;
  return axiosClient.post(url, params);
};

export const getCompanyTheme = (params) => {
  return axiosClient.get("/company/companyTheme", params);
};

export const getCompanyInfo = (params) => {
  return axiosClient.get("/company/companyInfo", params);
};
export const getCompanyConnectiveDetails = (params) => {
  return axiosClient.get("/company/companyConnectiveDetails", params);
};

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import { withStyles } from "@mui/styles";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { useIsTablet } from "../../../hooks/common.hooks";
import { primaryColor } from "../../../scss/colors.module.scss";
import { Logout } from "../../../svgs";
import { logout } from "../../../utils/auth";
import SideBarItem from "./side-bar-item";

const Accordion = withStyles({
  root: {
    backgroundColor: "transparent",
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    color: "#fff",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
  },
  expanded: {
    "&:first-child": {
      marginBottom: 0,
    },
  },
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    padding: 0,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "& svg": {
      transform: "rotate(0deg)",
      transition: "transform .2s ease-in-out",
    },
    margin: 0,
    "&$expanded": {
      margin: 0,
      "& svg": {
        transform: "rotate(180deg)",
      },
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles(() => ({
  root: {
    padding: 0,
    backgroundColor: "#fff",
    "& a.side-bar-item": {
      "&:hover": {
        backgroundColor: "#fff",
      },
      padding: "1rem",
      color: "#000",
    },
  },
}))(MuiAccordionDetails);

const DesktopSideBar = ({
  name: fullName = "",
  gitVersion = {},
  sideBarMenuItem = [],
}) => {
  const isTablet = useIsTablet();
  const [logoutExpanded, setLogoutExpanded] = useState(false);
  const [sidebarCollapse, setSidebarCollapse] = useState(true);
  const { gitTagName, gitDate } = gitVersion;

  useEffect(() => {
    setLogoutExpanded(false);
  }, [isTablet]);

  const handleLogoutExpanded = () => {
    setLogoutExpanded(!logoutExpanded);
  };
  const handleToggleSidebar = (value) => {
    if (!value) {
      setLogoutExpanded(false);
    }
    setSidebarCollapse(!value);
  };
  const handleLogout = () => {
    logout();
  };

  return (
    <div
      onMouseLeave={() => handleToggleSidebar(false)}
      onMouseEnter={() => handleToggleSidebar(true)}
      className={classNames("desktop-side-bar", {
        collapsed: sidebarCollapse,
      })}
    >
      <div className="desktop-side-bar-container">
        <Accordion
          className="position-relative"
          square
          expanded={logoutExpanded}
          onChange={!sidebarCollapse ? handleLogoutExpanded : undefined}
        >
          <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
            <div className="side-bar-user">
              <div className="side-bar-user__name">
                {sidebarCollapse ? fullName.match(/\b(\w)/g) : fullName}
              </div>
              <ExpandMoreIcon />
            </div>
          </AccordionSummary>
          <AccordionDetails className="logout-button-group">
            <div className="w-100">
              <div className="w-100" onClick={handleLogout}>
                <SideBarItem
                  icon={<Logout fillcolor={primaryColor} borderColor="#000" />}
                  title="Logout"
                  checked={false}
                  href=""
                />
              </div>
              <div className="code-version">
                <p className="m-0">Version: {gitTagName}</p>
                <p className="m-0">
                  Last Updated On: {new Date(gitDate).toLocaleString() || " "}
                </p>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <div className="side-bar-item-group">
          <div className="side-bar-item-group__container">
            {sideBarMenuItem.map((sideBarItem) => (
              <SideBarItem
                key={sideBarItem.title}
                icon={sideBarItem.icon}
                title={sideBarItem.title}
                href={sideBarItem.href}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

DesktopSideBar.propTypes = {};

export default DesktopSideBar;

import { navigate } from "gatsby";
import { useEffect, useMemo } from "react";
import { useMyInfo } from "../../hooks/user.hooks";
import { useCompanyInfo, useCompanyInfoById } from "../../hooks/company.hooks";
import { USER_ROLE } from "../../commons/enum";
import { getAllValueLocationStorage } from "../../utils/locastorage-utils";
import { navigateToDomainWithLocalStorageData } from "../../utils/domain-navigation";

export const DashboardLogicWrapper = ({ children }) => {
  const { data: myInfo, isError, isSuccess: isGetMyInfoSuccess } = useMyInfo();

  const {
    data: companyInfo,
    isSuccess: isGetCompanyInfoSuccess,
  } = useCompanyInfo();
  const {
    data: companyInfoById,
    isSuccess: isGetCompanyInfoByIdSuccess,
  } = useCompanyInfoById(myInfo?.companyId);

  useEffect(() => {
    if (isError) {
      navigate("/error-data");
    }
  }, [isError]);

  const isGettingDataSuccess = useMemo(() => {
    return (
      isGetCompanyInfoByIdSuccess &&
      isGetMyInfoSuccess &&
      isGetCompanyInfoSuccess
    );
  }, [
    isGetCompanyInfoByIdSuccess,
    isGetMyInfoSuccess,
    isGetCompanyInfoSuccess,
  ]);

  const isUserBelongToCompany = useMemo(() => {
    return isGettingDataSuccess ? companyInfo?.id === myInfo?.companyId : false;
  }, [isGettingDataSuccess, companyInfo?.id, myInfo?.companyId]);

  if (!isGettingDataSuccess) return null;

  if (myInfo.userRole === USER_ROLE.ADMIN || isUserBelongToCompany) {
    return children;
  }

  const localStorageData = getAllValueLocationStorage();
  navigateToDomainWithLocalStorageData(
    companyInfoById?.domain,
    localStorageData
  );
  return null;
};

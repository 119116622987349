import { getUserToken } from "../api-services/users";
import { cognitoLogout } from "./user-auth-provider";

export const isBrowser = () => typeof window !== "undefined";

export const getCsrfToken = async () => {
  const userIndex = await getUserToken();
  const { csrfToken } = userIndex;
  if (isBrowser()) {
    window.localStorage.setItem("token", csrfToken);
  }
  return csrfToken;
};

export const logout = () => {
  cognitoLogout().finally(() => {
    localStorage.clear();
    window.location.href = "/login";
  });
};

export const LANDING_PAGE_URL = "https://wealthx.au";

export const INITIAL_APP_STATE = {
  isNotConnectingToAnyBank: false,
  isPullingBasiqData: false,
  shouldCreatePendingScenario: false,
  pendingToCreateScenario: [],
};

export const AUTHENTICATED_REDIRECT_PAGES = [
  "login",
  "signup",
  "forgot-password",
  "logout",
  "recover-password",
];
export const NOT_DASHBOARD_ROUTES = [
  "login",
  "recover-password",
  "signup",
  "forgot-password",
  "referral",
  "logout",
  "confirmation",
];

export const USER_ROLE = {
  STAFF: "STAFF",
  USER: "USER",
  ADMIN: "ADMIN",
};
export const STAFF_ROLE = {
  WEALTH_PLANNER: "WEALTH_PLANNER",
  BROKER: "BROKER",
  REAL_ESTATE_AGENT: "REAL_ESTATE_AGENT",
  COMPANY_ADMIN: "COMPANY_ADMIN",
};

export const CONFIRMATION_TYPE = {
  SHARE_DATA: "SHARE_DATA",
};

export const CONFIRMATION_STATUS = {
  PENDING: "PENDING",
};

export const COMPANY_STATUS = {
  APPROVED: "APPROVED",
  AWAITING_APPROVAL: "AWAITING_APPROVAL",
  REJECTED: "REJECTED",
};

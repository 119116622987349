import { Grid } from "@mui/material";
import { LANDING_PAGE_URL } from "../../../commons/enum";
import { useCompanyInfo, useCompanyLogo } from "../../../hooks/company.hooks";

const footerItemList = [
  {
    title: "Login",
    url: `${LANDING_PAGE_URL}/login`,
  },
];

const SharingFooter = () => {
  const { data: companyInfo = {} } = useCompanyInfo();
  const { lightLogo } = useCompanyLogo();
  const { businessPhoneNumber = "", customerSupportEmail = "" } = companyInfo;
  return (
    <div className="sharing-footer">
      <Grid container className="sharing-footer-primary">
        <Grid item container xs={12} md={9}>
          {lightLogo && (
            <div className="sharing-footer__logo">
              <img src={lightLogo} alt="experity-icon" />
            </div>
          )}
          <Grid container className="sharing-footer-item__container">
            {footerItemList.map((footer) => {
              return (
                <Grid key={footer.title} item md={3} xs={6}>
                  <a href={footer.url}>{footer.title}</a>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={12}
          md={3}
          justify="flex-end"
          className="sharing-footer-time__container"
        >
          <div className="sharing-footer-time">
            <p className="font-weight-bold">Get In Touch</p>
            <p>
              Weekdays 8AM - 8PM AEST <br /> Saturday 10AM - 4PM <br /> Sunday -
              We Enjoy Australia
            </p>
            <div>
              <span>
                Call:{" "}
                <a href={`tel:${businessPhoneNumber}`}>
                  {businessPhoneNumber || "0416 338 438"}
                </a>
              </span>
              <br />
              <span>
                Email:{" "}
                <a
                  href={`mailto:${customerSupportEmail || "clint@wealthx.au"}`}
                >
                  {customerSupportEmail || "clint@wealthx.au"}
                </a>
              </span>
            </div>
          </div>
        </Grid>
      </Grid>
      <div className="sharing-footer-secondary">WEALTHX | ACN 640 789 813</div>
    </div>
  );
};

export default SharingFooter;

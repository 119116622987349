import * as React from "react";

function SvgComponent() {
  return (
    <svg
      id="prefix__Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 45 45"
      xmlSpace="preserve"
    >
      <g id="prefix__Group_5269" transform="translate(-176.125 -201.992)">
        <path
          id="prefix__Path_2062"
          className="icon-fill"
          d="M181.2 204.4l-.3 34.9 13.5 5.3v-35.8l-13.2-4.4z"
        />
        <path
          id="prefix__Path_2061"
          className="icon-stroke"
          d="M178.1 202.8l-.3 37.4 16 6v-38.3l-15.7-5.1z"
        />
        <path
          id="prefix__Path_2063"
          className="icon-stroke"
          d="M193.8 239.3h11v-12.6"
        />
        <path
          id="prefix__Path_2064"
          className="icon-stroke"
          d="M180.6 202.8h24.2v12.9"
        />
        <path
          id="prefix__Path_2065"
          d="M201.3 221.4h17.9"
          className="icon-stroke"
        />
        <path
          id="prefix__Path_2066"
          className="icon-stroke"
          d="M212.3 228.6l7.2-7.5-7.2-7.2"
        />
      </g>
    </svg>
  );
}

export default SvgComponent;

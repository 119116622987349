import { navigate } from "gatsby";
import { USER_ROLE } from "../commons/enum";
import { useShouldRedirectWhenAuthenticated } from "../hooks/common.hooks";
import { useMyInfo } from "../hooks/user.hooks";

export const AuthenticatedRedirect = ({ redirectTo = "/", children }) => {
  const shouldRedirect = useShouldRedirectWhenAuthenticated();

  if (shouldRedirect) {
    navigate(redirectTo);
  }

  return children;
};

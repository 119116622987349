import classNames from "classnames";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import { useMemo } from "react";
import { getCurrentPath } from "../../../utils/get-params-value";

const MenuLeftItem = ({ title = "", href = "", icon }) => {
  const pathName = getCurrentPath();
  const isSelectedMenuItem = useMemo(() => {
    return pathName.indexOf(href) === 1;
  }, [href, pathName]);

  const url = `/${href}`;
  return (
    <Link
      to={url}
      className={classNames("side-bar-item", {
        selected: isSelectedMenuItem,
      })}
    >
      <div className="side-bar-item__icon">{icon}</div>
      {!!title && <div className="side-bar-item__title">{title}</div>}
    </Link>
  );
};

MenuLeftItem.propTypes = {
  title: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.node,
};

export default MenuLeftItem;

import { useMediaQuery } from "@mui/material";
import { useLocation } from "@reach/router";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { IntercomAPI } from "react-intercom";
import {
  AUTHENTICATED_REDIRECT_PAGES,
  NOT_DASHBOARD_ROUTES,
} from "../commons/enum";
import DataLoadingContext from "../context/data-loading-context";
import { useIsLoggedIn, useMyInfo } from "./user.hooks";

export const useIsInDashboardRoutes = (pathname) => {
  return useMemo(
    () => NOT_DASHBOARD_ROUTES.every((item) => pathname.indexOf(item) < 0),
    [pathname]
  );
};
export const useIsAuthenticatedRedirectPages = (pathname) => {
  return useMemo(
    () =>
      AUTHENTICATED_REDIRECT_PAGES.some(
        (pageURL) => pathname.indexOf(pageURL) >= 0
      ),
    [pathname]
  );
};

export const useShouldRedirectWhenAuthenticated = () => {
  const isLoggedIn = useIsLoggedIn();
  const location = useLocation();
  const { data: userInfo } = useMyInfo();
  const isInDashboardRoutes = useIsInDashboardRoutes(location.pathname);
  return useMemo(() => {
    if (isLoggedIn && !isInDashboardRoutes && userInfo?.state === "VERIFIED") {
      return true;
    }
    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo?.state, isLoggedIn, isInDashboardRoutes]);
};

export const useStartLiveChat = () => {
  const { data: userInfo } = useMyInfo();
  const { setIsShowIntercom, isShowIntercom } = useContext(DataLoadingContext);
  const {
    firstName = "Anonymous",
    lastName = "",
    id = "",
    email = "",
  } = userInfo;
  const fullName = `${firstName} ${lastName}`;
  const user = {
    app_id: "skt381tt",
    user_id: id,
    email,
    name: fullName,
  };

  useEffect(() => {
    if (isShowIntercom) {
      IntercomAPI("boot", user);
      IntercomAPI("show");
      IntercomAPI("onHide", () => {
        setIsShowIntercom(false);
      });
    } else {
      IntercomAPI("shutdown");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowIntercom]);

  const startLiveChat = useCallback(() => {
    setIsShowIntercom(true);
  }, [setIsShowIntercom]);

  return [startLiveChat];
};

export const useIsTablet = () => {
  return useMediaQuery("(max-width:1024px)");
};

export const useIsMobile = () => {
  return useMediaQuery("(max-width: 767px)");
};

export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);
  return debouncedValue;
};

export const useDebouncedFunc = (func, delay = 300) => {
  const [id, setId] = useState(null);

  return (...args) => {
    if (id) {
      clearTimeout(id);
    }
    setId(
      setTimeout(() => {
        func(...args);
        setId(null);
      }, delay)
    );
  };
};
